@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-ExtraBold.woff2') format('woff2'),
        url('Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Regular.woff2') format('woff2'),
        url('Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Heavy.woff2') format('woff2'),
        url('Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Bold.woff2') format('woff2'),
        url('Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Medium.woff2') format('woff2'),
        url('Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

